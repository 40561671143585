<template>
  <div class="bg-white h-screen fixed w-64 overflow-y-auto">
    <Logo class="my-8 mx-auto" />
    <nav class="h-2/3 mt-10 pb-20 overflow-y-auto overflow-x-hidden">
      <template v-for="(item, i) in navItems">
        <router-link
          class="nav__link mb-2 px-6 py-3 text-xl flex items-center font-semibold text-gray-500 transition-all"
          :class="item.route === $route.path ? 'exact-active-class' : ''"
          :to="item.route"
          :key="i"
          v-if="item.subRoutes.length === 0"
        >
          <component :is="item.icon" class="opacity-40 mr-5" />
          {{ item.title }}
        </router-link>
        <div class="nav__link--dropdown mb-2 relative" :key="item.icon" v-else>
          <div
            class="nav__link px-6 py-3 text-xl flex justify-between items-center font-semibold text-gray-500 transition-all cursor-pointer"
          >
            <span class="flex items-center">
              <component :is="item.icon" class="opacity-40 mr-5" />
              {{ item.title }}
            </span>
            <triangle v-if="item.subRoutes.length !== 0" />
          </div>
          <div
            class="dropdown hidden absolute z-10 bg-white left-10 -right-5 p-3 rounded-md shadow-md"
          >
            <router-link
              :to="subRoute.route"
              v-for="(subRoute, i) in item.subRoutes"
              :key="i"
              exact-active-class="exact-active-class"
              class="nav__link p-2 rounded-md bg-background flex items-center mb-1"
            >
              <component :is="item.icon" class="opacity-40 mr-5" />
              {{ subRoute.title }}
            </router-link>
          </div>
        </div>
      </template>
    </nav>
    <div
      class="flex items-center pl-7 pr-8 py-4 fixed bottom-8 left-0 bg-white"
    >
      <img :src="user.image" class="rounded-xl w-12 h-12 bg-primary" alt="" />
      <div class="mx-2 text-sm">
        <h1 class="text-xl font-semibold">{{ user.firstname }}</h1>
        {{ allRoles[0] }}
      </div>
      <div class="cursor-pointer w-5 h-auto" @click="logUserOut()">
        <logout />
      </div>
    </div>
  </div>
</template>

<script>
import {
  EXECUTIVE_ROUTES,
  ADMIN_ROUTES,
  DESK_ROUTES,
  TOKEN,
  INVENTORY_ROUTES,
} from "@/utils/constants";

import { toStringify } from "@/utils/helpers";

export default {
  name: "SideNav",
  methods: {
    logUserOut() {
      this.$store.commit("SET_USER_DATA", {});
      this.$store.commit("SET_USER_ROLE", "");
      this.$store.commit("SET_PERMISSIONS", {});
      localStorage.removeItem(TOKEN);
      this.$router.push("/");
    },
  },
  computed: {
    user() {
      return this.$store.state.userDetail;
    },
    roles() {
      return localStorage.getItem("ROLE");
    },
    allRoles: {
      get() {
        return this.$store.state.roles;
      },
      set(newValue) {
        this.$store.commit("SET_ROLES", newValue);
      },
    },
    navItems() {
      let executiveRoute = toStringify(EXECUTIVE_ROUTES);
      let adminRoute = toStringify(ADMIN_ROUTES);
      let fdoRoutes = toStringify(DESK_ROUTES);
      let inventoryRoute = toStringify(INVENTORY_ROUTES);

      const userRoles = JSON.parse(this.roles);
      let nav = [];
      userRoles.forEach((role) => {
        switch (role.user_type_name) {
          case "Front Desk Officer":
            nav = nav.concat(fdoRoutes);
            this.allRoles = role.user_type_name;
            break;

          case "Branch Admin":
            nav = nav.concat(adminRoute);
            this.allRoles = role.user_type_name;
            break;

          case "Executive Admin":
            nav = nav.concat(executiveRoute);
            this.allRoles = role.user_type_name;
            break;

          case "Inventory Manager":
            nav = nav.concat(inventoryRoute);
            this.allRoles = role.user_type_name;
            break;

          default:
            break;
        }
      });

      const list = new Set(Array.from(nav));
      nav = [];
      list.forEach((element) => {
        nav.push(JSON.parse(element));
      });

      return nav;
    },
  },
  components: {
    Logo: () => import("@/assets/imgs/Logo.vue"),
    "app-grid": () => import("@/assets/icons/AppGridIcon.vue"),
    chart: () => import("@/assets/icons/ChartIcon.vue"),
    activity: () => import("@/assets/icons/ActivityIcon.vue"),
    ticket: () => import("@/assets/icons/TicketIcon.vue"),
    calendar: () => import("@/assets/icons/CalendarIcon.vue"),
    triangle: () => import("@/assets/icons/TriangleIcon.vue"),
    user: () => import("@/assets/icons/UserIcon.vue"),
    setting: () => import("@/assets/icons/SettingIcon.vue"),
    logout: () => import("@/assets/icons/LogoutIcon.vue"),
  },
};
</script>

<style scoped>
.nav__link:hover,
.exact-active-class {
  background-image: linear-gradient(to right, #c6b277e0, 5%, transparent);
  color: #c6b277;
}

.nav__link--dropdown:hover .dropdown {
  display: block;
}
</style>
