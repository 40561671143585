<template>
  <div class="flex min-h-screen overflow-y-hidden">
    <side-nav class="z-50 overflow-y-hidden h-full" />
    <router-view class="w-full bg-background overflow-y-auto pl-72" />
  </div>
</template>

<script>
import SideNavVue from "../components/Base/SideNav.vue";

export default {
  name: "Home",
  components: {
    "side-nav": SideNavVue,
  },
};
</script>
